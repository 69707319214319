// import { handleCollection } from "../Action/Action";

const initialAuthState = {
  AccessToken: "",
  HeaderValue: "All",
  data: [],
  cartContents: [],
  cartStatus: false,
  transaction: false,
  ordercmsData: [],
  walletCMSData: [],
  HomeCMSData: [],
  createListingCategory: [],
  SearchData: "",
  Successmodal: {
    show: false,
    msg: "",
  },
  FailureModal: {
    show: false,
    msg: "",
  },
  PaymentModal: {
    show: false,
  },
  aboutCMS: [],
  csrData: [],
  isrData: [],
  beneuserData: null,
  beneuserWishlist: [],
  endorseuserData: null,
  activeTab: -1,
  goodwillData: [],
  relatedServiceData: [],
  isprogressOrder: false,
  accountUpdated: false,
  collectionData: [],
  benefitayListingPagenumber: 1,
  benefitaryListing: [],
  cartData: [],
  dealsData: [],
  categoryUpdated: false,
  categoryData: {
    search_term: null,
    category: null,
    classification: null,
    deals_check: 0,
  },
  serviceValue: [],
  bankList: [],
  beneficiaryMasterData: [],
  beneficiaryPagenumber: 1,
  SEOMeta: {},
  seoLoaderStatus: true,
  isSignoutBtnClicked: false,
};

const handleSignoutBtn = (state, action) => {
  return {
    ...state,
    isSignoutBtnClicked: action.data,
  };
};

const SeoLoader = (state, action) => {
  return {
    ...state,
    seoLoaderStatus: action.isLoaderStatus,
  };
};

const UserStore = (state, action) => {
  return {
    ...state,
    data: action.data,
  };
};

const updateSEOMETA = (state, action) => {
  return {
    ...state,
    SEOMeta: action.SEOMeta,
  };
};



const SetAccessToken = (state, action) => {
  return {
    ...state,
    AccessToken: action.AccessToken,
  };
};

const SetHomePageCms = (state, action) => {
  return {
    ...state,
    HomeCMSData: action.HomeCMSData,
  };
};

const SuccessModalShow = (state, action) => {
  return {
    ...state,
    Successmodal: action.Successmodal,
  };
};


const FailureModalShow = (state, action) => {
  return {
    ...state,
    FailureModal: action.FailureModal,
  };
};
const aboutCMSData = (state, action) => {
  return {
    ...state,
    aboutCMS: action.aboutCMS,
  };
};
const AccountUpdateStatus = (state, action) => {
  return {
    ...state,
    accountUpdated: action.accountUpdated,
  };
};


function rootReducer(state = initialAuthState, action) {
  switch (action.type) {
    case "ACCESS_TOKEN":
      return SetAccessToken(state, action);
    case "USER_DATA":
      return UserStore(state, action);
    case "ABOUT_CMS_DATA":
      return aboutCMSData(state, action);

    case "UPDATE_SEO_META":
      return updateSEOMETA(state, action);
    case "SEO_LOADER_STATUS":
      return SeoLoader(state, action);
    case "IS_SIGNOUT_CLICKED":
      return handleSignoutBtn(state, action);

    default:
      return state || null;
  }
}

export { rootReducer };
